import {ApplicationConfig, importProvidersFrom} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {provideRouter, RouterModule} from "@angular/router";
import {MonacoEditorModule} from "ngx-monaco-editor-v2";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {ToastrModule} from "ngx-toastr";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgChartsModule} from "ng2-charts";
import {QuillModule} from "ngx-quill";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MAT_CHIPS_DEFAULT_OPTIONS} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {environment} from "../environments/environment";
import {getAuth, provideAuth} from "@angular/fire/auth";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {getFunctions, provideFunctions} from "@angular/fire/functions";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import {routes} from "./app.routes";
import {MatNativeDateModule} from "@angular/material/core";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      MatDialogModule,
      MatSelectModule,
      MatSnackBarModule,
      ReactiveFormsModule,
      MatInputModule,
      RouterModule,
      MonacoEditorModule.forRoot(),
      MatAutocompleteModule,
      ToastrModule.forRoot(),
      MatTooltipModule,
      NgChartsModule,
      QuillModule.forRoot(),
      MatNativeDateModule
    ),
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [COMMA, ENTER]
      }
    },
    provideRouter(routes),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideAnimationsAsync()
  ]
};
