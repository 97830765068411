import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import {appConfig} from "./app/app.config";

if (environment.production) {
  enableProdMode();

  // HACK: Don't log to console in production environment.
  if(window){
    window.console.log = window.console.warn = window.console.info = function(){
      // Don't log anything.
    };
  }
}

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
