<h1 class="mb-4 text-4xl md:text-6xl text-center font-bold font-heading tracking-px-n leading-tight">Reset your password</h1>
<p class="mb-12 font-medium text-lg text-gray-600 leading-normal">Enter your email and follow the instructions in your inbox.</p>
<form [formGroup]="resetForm">
  <label class="block mb-5 text-left">
    <input class="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-primary-300"
           id="signInInput2-1" type="text" placeholder="Email address" formControlName="email">
    <small class="text-red-600" *ngIf="email.touched && email.invalid">Must be a valid email</small>

  </label>
  <button class="mb-8 py-4 px-9 w-full text-white font-semibold border border-primary-700 rounded-xl shadow-4xl focus:ring focus:ring-primary-300 bg-primary-600 hover:bg-primary-700 transition ease-in-out duration-200"
          (click)="onReset()" type="button" *ngIf="!processing">Reset password</button>
  <button class="btn btn-processing btn-lg w-full" disabled *ngIf="processing">
    <i class="fas fa-circle-notch"></i>
    Sending...
  </button>
  <p class="font-medium">
    <span class="mr-1">Already have a password?</span>
    <a class="text-primary-600 hover:text-primary-700" routerLink="../login">Login</a>
  </p>

  <div class="text-left mt-6">
    <div class="bg-green-50 rounded-lg p-2 my-3" *ngIf="success">
      <i class="fas fa-info-circle mr-1"></i>
      Reset password email sent! Check your inbox.
    </div>
    <div class="bg-amber-50 rounded-lg p-2 my-3" *ngIf="authSvc.invalidEmail || authSvc.wrongCredentials || authSvc.userNotFound">
      <i class="fas fa-exclamation-triangle mr-1"></i>
      Wrong email or password
    </div>
    <div class="bg-amber-50 rounded-lg p-2 my-3" *ngIf="authSvc.tooManyRequests">
      <i class="fas fa-exclamation-triangle mr-1"></i>
      Slow down! We have detected too many failed attempts. Wait some minutes and try again.
    </div>
    <div class="bg-amber-50 rounded-lg p-2 my-3" *ngIf="authSvc.unknownError">
      <i class="fas fa-exclamation-triangle mr-1"></i>
      Error: {{authSvc.unknownError}}
    </div>
  </div>
</form>
