import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthService} from "../../../shared/services/auth.service";
import {Router, RouterLink} from "@angular/router";
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, RouterLink]
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup
  processing = false
  constructor(
    public authSvc: AuthService,
    private router: Router
  ) {
    this.signupForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    })
  }


  get name() { return this.signupForm.get('name') as FormControl }
  get email() { return this.signupForm.get('email') as FormControl }
  get password() { return this.signupForm.get('password') as FormControl }
  ngOnInit(): void {
  }

  onSignup() {
    this.authSvc.resetErrors()
    this.signupForm.markAllAsTouched()
    if (this.signupForm.invalid) { return }
    this.processing = true
    this.authSvc.signUpByEmail(this.name.value, this.email.value, this.password.value)
      .then(async () => {
        await this.router.navigate(['/']);
      })
      .catch((err) => {
        this.authSvc.errorSelector(err)
        this.processing = false
      });
  }

}
