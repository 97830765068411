<h1 class="mb-4 text-4xl md:text-6xl text-center font-bold font-heading tracking-px-n leading-tight">Join for free</h1>
<p class="mb-12 font-medium text-lg text-gray-600 leading-normal">Sign up now to unlock the power of enriched and actionable data.</p>
<form [formGroup]="signupForm">
  <label class="block mb-5">
    <input formControlName="name" class="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-primary-300" id="signUpInput2-1" type="text" placeholder="Your name">
    <small class="text-red-600" *ngIf="name.touched && name.invalid">Required</small>
  </label>
  <label class="block mb-5">
    <input formControlName="email" class="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-primary-300" id="signUpInput2-2" type="email" placeholder="Email Address">
    <small class="text-red-600" *ngIf="email.touched && email.invalid">Must be a valid email</small>
  </label>
  <label class="block mb-5">
    <input formControlName="password" class="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-primary-300" id="signUpInput2-3" type="password" placeholder="Create Password">
    <small class="text-red-600" *ngIf="password.touched && password.invalid">At least 8 characters</small>
  </label>
  <button (click)="onSignup()" *ngIf="!processing" class="py-4 px-9 w-full text-white font-semibold border border-primary-700 rounded-xl shadow-4xl focus:ring focus:ring-primary-300 bg-primary-600 hover:bg-primary-700 transition ease-in-out duration-200" type="button">Create Account</button>
  <button class="btn btn-processing btn-lg w-full" disabled *ngIf="processing">
    <i class="fas fa-circle-notch"></i>
    Processing...
  </button>
  <div class="text-left">
    <div class="bg-amber-50 rounded-lg p-2 my-3" *ngIf="authSvc.invalidEmail || authSvc.wrongCredentials || authSvc.userNotFound">
      <i class="fas fa-exclamation-triangle mr-1"></i>
      Wrong email or password
    </div>
    <div class="bg-amber-50 rounded-lg p-2 my-3" *ngIf="authSvc.tooManyRequests">
      <i class="fas fa-exclamation-triangle mr-1"></i>
      Slow down! We have detected too many failed attempts. Wait some minutes and try again.
    </div>
    <div class="bg-amber-50 rounded-lg p-2 my-3" *ngIf="authSvc.unknownError">
      <i class="fas fa-exclamation-triangle mr-1"></i>
      Error: {{authSvc.unknownError}}
    </div>
  </div>

  <div class="divider-new version-2 my-6 text-gray-700 flex items-center justify-center flex-row">
    <h6 class="small mx-2">OR</h6>
  </div>

  <div class="flex flex-wrap justify-center -m-2">
    <div class="w-full p-2">
      <button class="flex items-center w-full justify-center p-4 bg-white hover:bg-gray-50 border rounded-lg transition ease-in-out duration-200" (click)="authSvc.loginWithGoogle()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px"><path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/></svg>
        <span class="font-semibold leading-normal ml-1">Sign in with Google</span>
      </button>
    </div>
  </div>

  <p class="font-medium mt-8">
    <span class="mr-1">Already have an account?</span>
    <a class="text-primary-600 hover:text-primary-700" routerLink="../login">Login</a>
  </p>
</form>
