import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthService} from "../../../shared/services/auth.service";
import {RouterLink} from "@angular/router";
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, RouterLink]
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup
  processing = false
  success = false
  constructor(
    public authSvc: AuthService,
  ) {
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
    })
  }
  get email() { return this.resetForm.get('email') as FormControl }

  ngOnInit(): void {
  }

  onReset() {
    this.success = false
    this.resetForm.markAllAsTouched()
    this.authSvc.resetErrors()
    if (this.resetForm.invalid) { return }
    this.processing = true
    this.authSvc.sendResetPasswordEmail(this.email.value)
      .then(() => {
        this.success = true
      })
      .catch((err) => {
        this.authSvc.errorSelector(err)
        this.processing = false
      })
  }

}
