import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {WorkflowDetailComponent} from "../../views/user/workspace/workflows/workflow-detail/workflow-detail.component";

@Injectable({
  providedIn: 'root'
})
export class WorkflowUnsavedDataGuard  {
  canDeactivate(
    component: WorkflowDetailComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.hasUnsavedData()) {
      return confirm("You are about to leave this page. Unsaved changes will be lost. Are you sure?")
    }
    return true
  }

}
