import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { prettyPrintJson } from 'pretty-print-json';
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-pretty-print-json',
  templateUrl: './pretty-print-json.component.html',
  styleUrls: ['./pretty-print-json.component.scss']
})
export class PrettyPrintJsonComponent implements AfterViewInit, OnChanges {
  @ViewChild('jsonWrapper') jsonWrapper: ElementRef
  @Input() object: any
  @Input() white = false

  constructor(
    private helperSvc: HelperService
  ) {
  }
  ngAfterViewInit() {
    this.jsonWrapper.nativeElement.innerHTML = prettyPrintJson.toHtml(this.helperSvc.sortObjectKeys(this.object))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['object']) {
      if (!changes['object'].firstChange) {
        this.jsonWrapper.nativeElement.innerHTML = prettyPrintJson.toHtml(this.helperSvc.sortObjectKeys(this.object))
      }
    }
  }
}
