import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';

declare global {
  let Paddle: any
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {
}
