import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, FormsModule, ReactiveFormsModule, AsyncPipe]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  processing = false
  constructor(
    public authSvc: AuthService,
    private router: Router
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', Validators.required)
    })
  }

  get email() { return this.loginForm.get('email') as FormControl }
  get password() { return this.loginForm.get('password') as FormControl }
  ngOnInit(): void {
  }

  onLogin() {
    this.loginForm.markAllAsTouched()
    this.authSvc.resetErrors()
    if (this.loginForm.invalid) { return }
    this.processing = true
    this.authSvc.loginWithEmail(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value)
      .then(async () => {
        await this.router.navigate(['/']);
      })
      .catch((err) => {
        this.authSvc.errorSelector(err)
        this.processing = false
      });
  }

}
