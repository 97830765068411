import {Routes} from '@angular/router';
import {AuthGuard} from "./shared/guards/auth.guard";
import {LoginComponent} from "./views/auth/login/login.component";
import {SignupComponent} from "./views/auth/signup/signup.component";
import {ResetPasswordComponent} from "./views/auth/reset-password/reset-password.component";
import {ValidateEmailComponent} from "./views/auth/validate-email/validate-email.component";
import {WorkflowUnsavedDataGuard} from "./shared/guards/workflow-unsaved-data.guard";

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./views/user/user.component').then(m => m.UserComponent),
    canActivate: [AuthGuard],
    children: [
      { path: '', title: 'Home', loadComponent: () => import('./views/user/home/home.component').then(m => m.HomeComponent) },
      { path: 'account', title: 'My Account', loadComponent: () => import('./views/user/account/account.component').then(m => m.AccountComponent) },
      { path: 'workspace', redirectTo: '' },
      {
        path: 'workspace/:workspaceId',
        loadComponent: () => import('./views/user/workspace/workspace.component').then(m => m.WorkspaceComponent),
        children: [
          { path: 'overview', title: 'Workspace Overview', loadComponent: () => import('./views/user/workspace/overview/overview.component').then(m => m.OverviewComponent) },
          {
            path: 'workflows',
            children: [
              { path: '', title: 'Workflows', loadComponent: () => import('./views/user/workspace/workflows/list/workflows.component').then(m => m.WorkflowsComponent), },
              { path: ':workflowId', title: 'Edit workflow', loadComponent: () => import('./views/user/workspace/workflows/workflow-detail/workflow-detail.component').then(m => m.WorkflowDetailComponent), canDeactivate: [WorkflowUnsavedDataGuard] }
            ]
          },
          { path: 'integrations', title: 'Integrations', loadComponent: () => import('./views/user/workspace/integrations/integrations.component').then(m => m.IntegrationsComponent) },
          { path: 'computations', title: 'Computations', loadComponent: () => import('./views/user/workspace/computations/computations.component').then(m => m.ComputationsComponent) },
          {
            path: 'settings',
            loadComponent: () => import('./views/user/workspace/settings/settings.component').then(m => m.SettingsComponent),
            children: [
              { path: 'general', title: 'Settings', loadComponent: () => import('./views/user/workspace/settings/workspace-settings/workspace-settings.component').then(m => m.WorkspaceSettingsComponent) },
              { path: 'team', title: 'Team', loadComponent: () => import('./views/user/workspace/settings/workspace-team/workspace-team.component').then(m => m.WorkspaceTeamComponent) },
              { path: 'billing', title: 'Billing', loadComponent: () => import('./views/user/workspace/settings/workspace-billing/workspace-billing.component').then(m => m.WorkspaceBillingComponent) },
              { path: '**', redirectTo: 'general' }
            ]
          },
          {
            path: 'history',
            children: [
              { path: '', title: 'History', loadComponent: () => import('./views/user/workspace/history/all/history.component').then(m => m.HistoryComponent) },
              { path: 'issues', title: 'Runs with Issues', loadComponent: () => import('./views/user/workspace/history/history-issues/history-issues.component').then(m => m.HistoryIssuesComponent) },
              { path: ':runId', title: 'Workflow Run', loadComponent: () => import('./views/user/workspace/history/history-detail/history-detail.component').then(m => m.HistoryDetailComponent) },
            ]
          },
          { path: 'issues', redirectTo: 'history/issues' }, // Legacy fix
          {
            path: 'extractor',
            children: [
              { path: '', title: 'Extractor', loadComponent: () => import('./views/user/workspace/extractor/list/extractor.component').then(m => m.ExtractorComponent) },
              { path: ':extractionId', title: 'Edit Extractor', loadComponent: () => import('./views/user/workspace/extractor/extractor-detail/extractor-detail.component').then(m => m.ExtractorDetailComponent) },
            ]
          },
          { path: '**', redirectTo: 'overview' },
        ]
      },
      { path: 'templates', title: 'Templates', loadComponent: () => import('./views/user/templates/templates.component').then(m => m.TemplatesComponent) },
    ]
  },
  {
    path: 'auth',
    loadComponent: () => import('./views/auth/auth.component').then(m => m.AuthComponent),
    children: [
      { path: 'login', title: 'Login - Datamorf', component: LoginComponent },
      { path: 'signup', title: 'Sign up - Datamorf', component: SignupComponent },
      { path: 'reset-password', title: 'Reset password - Datamorf', component: ResetPasswordComponent },
      { path: 'validate-email', title: 'Validate Email - Datamorf', component: ValidateEmailComponent },
      { path: '**', redirectTo: 'login' }
    ]
  },
  { path: 'redeem-code', title: 'Redeem Code', loadComponent: () => import('./views/redeem-code/redeem-code.component').then(m => m.RedeemCodeComponent) },
  { path: 'integrations/:vendor', title: 'Integration', loadComponent: () => import('./views/integrations/integrations-auth.component').then(m => m.IntegrationsAuthComponent) },
  { path: 'checkout', title: 'Datamorf Checkout', loadComponent: () => import('./views/checkout/checkout.component').then(m => m.CheckoutComponent) },
  { path: 'test', title: 'Test', loadComponent: () => import('./views/test/test.component').then(m => m.TestComponent) },

  { path: '**', redirectTo: '/' }
];
